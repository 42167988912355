import React from "react";

export default function IconCredit({ color = '#fff',color_2="#a9a1a1"}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51.371" height="33.385" viewBox="0 0 51.371 33.385">
  <g id="Group_72" data-name="Group 72" transform="translate(-0.476 -0.476)">
    <g id="Group_485" data-name="Group 485">
      <path id="Path_197" data-name="Path 197" d="M30.145.75H45.307a5.314,5.314,0,0,1,5.314,5.314V27.321a5.314,5.314,0,0,1-5.314,5.314H6.064A5.314,5.314,0,0,1,.75,27.321V6.064A5.314,5.314,0,0,1,6.064.75h11.57" transform="translate(0.476 0.476)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1.5"/>
      <line id="Line_1" data-name="Line 1" x2="1.161" transform="translate(23.029 1.226)" fill={color} stroke="#707070" strokeWidth="1.5"/>
      <line id="Line_2" data-name="Line 2" x2="1.161" transform="translate(23.029 1.226)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1.5"/>
      <line id="Line_3" data-name="Line 3" x2="13.294" transform="translate(7.739 8.5)" fill={color} stroke="#707070" strokeWidth="1.5"/>
      <line id="Line_4" data-name="Line 4" x2="13.294" transform="translate(7.739 8.5)" fill="none" stroke={color_2} strokeLinecap="round" strokeWidth="1.5"/>
      <line id="Line_5" data-name="Line 5" x2="17.762" transform="translate(25.076 24.115)" fill={color} stroke="#707070" strokeWidth="1.5"/>
      <line id="Line_6" data-name="Line 6" x2="17.762" transform="translate(25.076 24.115)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1.5"/>
      <circle id="Ellipse_42" data-name="Ellipse 42" cx="3.205" cy="3.205" r="3.205" transform="translate(37.155 8.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1.5"/>
    </g>
  </g>
</svg>
  );
}