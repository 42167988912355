import React from 'react';

import './Company.css';

import img1 from '../../assets/images/c1.svg';
import img2 from '../../assets/images/c2.svg';
import img3 from '../../assets/images/c3.svg';
import img4 from '../../assets/images/c4.svg';
import img5 from '../../assets/images/company.png';

const Company = () => {
	return (
		<div className="company-wrapper">
			<div className="heading">
				<h2 className="m-auto">COMPANY</h2>
			</div>
			<div className="companyWrapper">
				<div className="top text-center">
					<h2 className="heading-2 mb20 text-center">
						<span className="strong">Our Mission</span>
					</h2>
					<p>Spectra Traders  is on a mission to provide a safe, transparent, instant, and convenient eWallet solution to banks and financial institutions across the globe.</p>
				</div>

				<div className="middle flex justify-center gap-50">
					<div className="right">
						<img src={img5} alt="img" />
					</div>
					<div className="left self-center">
						<h2 className="heading-2 mb20">Secure and reliable Services Provided by Spectra Traders  is India's best leading online service provider.</h2>

						<p>
							We at Spectra Traders  deliver Aadhaar Enabled Payment systems ( AEPS), Domestic Money Transfer, Recharge( Mobile, Dth, Data Card), and Bill Payment System( Electricity,
							Landline, Mobile Bill Payment) of all leading service providers at the unbelievably lowest price. Now you don’t have to stand in queue for all Recharge, Bill Payment, AEPS
							and Money Transfer, You Can pay easily all these payments with Spectra Traders  store services in India. You can transfer money from any place to any bank account at any time in
							India using Spectra Traders  Store domestic money transfer service.
						</p>
					</div>
				</div>

				<div className="bottom">
					<div className="top text-center">
						<h2 className="heading-2 mb20 text-center">
							<span className="strong">What makes us different</span>
						</h2>
						<p>Leading mobile finance solution and every FinTech’s first choice. There are many things that makes Pay2Mpbiles different from the rest. Some of them are mentioned here.</p>
					</div>

					<div className="card-box flex justify-center gap-30">
						<div className="card text-center">
							<div className="img center">
								{' '}
								<img className="mb30" src={img1} alt="img-1" />
							</div>
							<h3>Customer satisfaction</h3>
							<p>Customer satisfaction is always our top priority and we ensure that by fulfilling all our client’s requirements.</p>
						</div>

						<div className="card text-center">
							<div className="img center">
								{' '}
								<img className="mb30" src={img2} alt="img-1" />
							</div>
							<h3>Committed support</h3>
							<p>Our proficient professionals are always committed to provide 24*7 support via calls, email, chat, and other media.</p>
						</div>
						<div className="card text-center">
							<div className="img center">
								{' '}
								<img className="mb30" src={img3} alt="img-1" />
							</div>
							<h3>Peace of mind</h3>
							<p>We work with full dedication to ensure that the project gets completed on time. Thus, providing you the peace of mind.</p>
						</div>
						<div className="card text-center">
							<div className="img center">
								{' '}
								<img className="mb30" src={img4} alt="img-1" />
							</div>
							<h3>100% transparency</h3>
							<p>Keeping 100% transparency is our mantra as we keep all the unexpected surprises away from you.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Company;
