import React from "react";

export default function IconSendmoney({ color = '#fff' ,color_2="#a9a1a1" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.886"
      height="41.58"
      viewBox="0 0 44.886 41.58"
    >
      <g data-name="Group 349" transform="translate(1.061 1.06)">
        <rect
          width="32.509"
          height="32.509"
          fill="none"
          className="path-stroke"
          stroke={color}
          strokeWidth="1.5"
          data-name="Rectangle 531"
          rx="16.254"
          transform="translate(5.233 3.475)"
        ></rect>
        <path
          fill={color_2}
          d="M15.231 4.414a1.018 1.018 0 01-1.019 1.019h-1.033A5.107 5.107 0 018.1 10.19H6.873l5.266 4.31a1.016 1.016 0 01.143 1.434 1.028 1.028 0 01-.788.374 1.017 1.017 0 01-.646-.231L3.373 9.959a1.024 1.024 0 01.646-1.814H8.1a3.06 3.06 0 003.037-2.718H4.019a1.019 1.019 0 110-2.039h6.619A3.048 3.048 0 008.1 2.029H4.019a1.019 1.019 0 110-2.039h10.193a1.019 1.019 0 110 2.039h-2.039a5.248 5.248 0 01.734 1.359h1.311a1.018 1.018 0 011.019 1.019z"
          transform="translate(12.369 12.289)"
        ></path>
        <g
          fill="none"
          className="path-stroke"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.5"
          data-name="Group 242"
          transform="translate(21.05)"
        >
          <path d="M0 3.474h21.152" data-name="Path 365"></path>
          <path
            d="M18.239 0l3.475 3.475-3.475 3.475"
            data-name="Path 366"
          ></path>
        </g>
        <g
          fill="none"
          className="path-stroke"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.5"
          data-name="Group 243"
          transform="translate(0 32.509)"
        >
          <path d="M21.717 3.474H.565" data-name="Path 365"></path>
          <path d="M3.475 0L0 3.475 3.475 6.95" data-name="Path 366"></path>
        </g>
      </g>
    </svg>
  );
}