import { useState } from 'react';
import { Wrapper } from './style';

import Electricity from '../../Components/Electricity';
import { subTabs } from './constants';
import TabContent from '../Navigation/TabContent';
import OfferBanner from '../OfferBanner';

export default function Component({ className = '', moreList, setMoreList }) {
	const [subActiveTab, setSubActiveTab] = useState('');

	const ChangeSubTabs = (id) => {
		setSubActiveTab(id);
		setMoreList(false);
	};

	const tabContentMap = {
		subTab1: <Electricity className="electricity-form" BillType={'Gas'} billHeading={'Gas'} />,
		subTab2: <Electricity className="electricity-form" BillType={'Landline'} billHeading={'LANDLINE'} />,
		subTab3: <Electricity className="electricity-form" BillType={'INSURANCE'} billHeading={'Insurance'} />,
		subTab4: <Electricity className="electricity-form" BillType={'EMI'} billHeading={'EMI'} />,
		subTab5: <Electricity className="electricity-form" BillType={'OTT'} billHeading={'OTT'} />,
		subTab6: <Electricity className="electricity-form" BillType={'Gas_Cylinder'} billHeading={'Gas Cylinder'} />,
		subTab7: <Electricity className="electricity-form" BillType={'FASTAG'} billHeading={'Fasttag'} />,
		subTab8: <Electricity className="electricity-form" BillType={'Metro'} billHeading={'Metro'} />,
		subTab9: <Electricity className="electricity-form" BillType={'CableTV'} billHeading={'Cable TV'} />,
		subTab10: <Electricity className="electricity-form" BillType={'Apartment'} billHeading={'Apartment'} />,
	};

	return (
		<>
			{moreList && (
				<Wrapper className={className}>
					<div className="inner-form">
						{subTabs.map((value, i) => {
							return (
								<>
									{value.services?.length > 0 && (
										<h2 key={i} className="service-heading">
											{value.title}
										</h2>
									)}
									{value?.services?.length > 0 &&
										value.services.map((service, index) => {
											return (
												<div key={index} id={value.id} onClick={() => ChangeSubTabs(service.id)} className={subActiveTab === service.id ? 'subTab active' : 'subTab'}>
													<div className="subtab-icon">
														<img src={service.icon} alt={service.title} />
													</div>
													<div className="subtab-title">{service.title}</div>
												</div>
											);
										})}
								</>
							);
						})}
					</div>
				</Wrapper>
			)}
			{Object.keys(tabContentMap).map((subTabId, i) => {
				return (
					<TabContent id={subTabId} activeTab={subActiveTab}>
						{tabContentMap[subTabId]}
					</TabContent>
				);
			})}
			{
				subActiveTab === "" && <div style={{marginTop:"60px"}}><OfferBanner  /></div>
			}
		</>
	);
}
