import styled from 'styled-components';

export const TableWrapper = styled.div`
	overflow: auto;
	border: 1px solid #00297024;
	border-radius: 10px;
	font-size: 14px;
	&.mobile-plan-table {
		max-height: 300px;
	}
	.table {
		width: 100%;
		border-collapse: collapse;
		box-shadow: 0px 2px 3px #0029700d;
		tbody {
			tr {
				border-bottom: 1px solid #00297024;

				&:last-child {
					border-bottom: none;
				}
			}
		}
		th,
		td {
			padding: 10px 20px;
			text-align: left;
			text-transform: capitalize;
			&.text-right {
				text-align: right;
			}
			&.text-center {
				text-align: center;
			}
			&.text-left {
				text-align: left;
			}
		}
		th {
			background-color: #edfafe;
			border: none;
			white-space: nowrap;
			font-weight: 500;

			&.remark {
				padding: 0 60px;
			}
		}
		td {
			white-space: nowrap;
			.select-link > div {
				display: inline-block;

				color: #00baf2;
				border: 2px solid #00baf2;
				padding: 5px 15px;
				text-align: center;
				min-width: 100px;
				border-radius: 2px;
				white-space: nowrap;
				cursor: pointer;
			}
			&.remark {
				white-space: normal;
				text-align: center;
			}
			&.fail {
				color: red;
			}
			&.success {
				color: green;
			}
			.image-with-text {
				display: flex;
				align-items: center;
				img {
					margin-right: 10px;
				}
			}
		}
	}
`;

export const TabContentWrapper = styled.div`
	position: relative;
    border-radius: 10px;
	box-shadow: var(--tab-content-boxShadow);
	margin-top: 30px;
	border-radius: 10px;
	

	.msg {
		text-align: center;
		border-radius: 2px;
	}

	.msg.err {
		color: red;
		background-color: rgb(255 55 55 / 20%);
	}
	.tab-content-left {
		background-color: var(--tab-content-left-bgColor);
		padding: 23px;
		position: relative;
		border-right: var(--tab-content-border);
		min-height: 155px;
		border-radius: 10px;


		overflow: visible; /* Enable vertical scrolling if content exceeds max-height */
		&>.flex{
              flex-wrap: wrap;
		} 
	}
	.tab-content-right {
		min-height: 400px;
		flex: 1 1;
		background-color: #fff;
		padding: 30px;
		margin: 0 0;
		margin-top: 20px;
		border-radius: 10px;
	}
	h2 {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		margin-bottom: 15px;
	}
	.radio-group {
		display: flex;
		align-items: center;
		margin-bottom: 9px;
		white-space: nowrap;
		.radio {
			display: flex;
			align-items: center;
			gap: 4px;
		}
	}
	.form-field {
		flex: 1 1 200px;
		max-height: 44px;
		input[type='text'],
		.item-select {
			height: 44px;
			font-size: 13px;
			font-weight: 500;
			color: var(--tab-content-input-color);
			width: 100%;
			border: var(--tab-content-input-border);
			border-radius: 4px;
			outline: none;
			padding: 0 8px;
			&::placeholder {
				color: var(--tab-content-input-color);
			}
		}
		.hide {
			display: none;
		}
	}
	.form-field-select-bank {
		margin-top: 10px;
		margin-bottom: 24px;
		position: relative;
		.searchBank {
			margin-bottom: 0;
		}
		.field-label {
			font-size: 15px;
			font-weight: 600;
			line-height: 23px;
			padding-bottom: 10px;
		}
		.radio-group {
			display: flex;
			flex-wrap: wrap;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			margin-bottom: 10px;
		}
		.radio {
			width: 254px;
			padding: 6px 10px;
			position: relative;
			overflow: hidden;
			&.active {
				background-color: #00baf2;
				color: #fff;
			}
			&:hover {
				background-color: #00baf2;
				color: #fff;
			}

			input {
				position: absolute;
				left: -9999px;
			}
		}
		.radio .icon-with-name {
			display: block;
			white-space: break-spaces;
		}
	}

	.bankList {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 258px;
		background-color: #fff;
		top: 100%;
		white-space: break-spaces;
		position: absolute;
		top: 100%;
		box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
		.radio {
			width: 100%;
			padding: 4px 8px;
			position: relative;
			overflow: hidden;
			input {
				position: absolute;
				left: -999999px;
			}

			&:hover {
				background-color: #00baf2;
				color: #fff;
			}
			&.active {
				background-color: #00baf2;
				color: #fff;
			}
		}
	}

	
	.button-outline {
		width: 150px;
		&.loading {
			cursor: not-allowed;
			.spinner {
				display: inline-block;
				width: 14px;
				height: 14px;
				border: 2px solid #00baf2;
				border-top: 2px solid transparent;
				border-radius: 50%;
				margin-right: 4px;
				animation: spin 2s infinite linear;
			}

			@keyframes spin {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
	}
	.mt20 {
		margin-top: 20px;
	}
	@media only screen and (max-width: 767px) {
		background-color: #fff;

		.tab-content-right {
			position: absolute;
			max-width: 100%;
			width: 100%;
			padding: 20px 10px;
			box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			visibility: hidden;
			opacity: 0;
			padding-top: 38px;
			transition: visibility 0.3s, opacity 0.3s;

			&.active {
				visibility: visible;
				opacity: 1;
			}
		}

		.tab-content-left {
			flex: 0 1 auto;
			width: 100%;
			max-width: 333px;
			margin: auto;
			border-right: none;
		}
		.close-pop {
			width: 28px;
			height: 28px;
			position: absolute;
			right: 4px;
			top: 4px;
			z-index: 1;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		}
	}
`;

export const PlanWrapper = styled.div`
	background-color: #fff;
	position: relative;
	flex: 1;
	.plan-tabs-wrapper {
		display: flex;
		overflow-x: auto;
		.plan-tab {
			padding: 10px 30px;
			border-bottom: 4px solid transparent;
			color: #888;
			text-transform: capitalize;
			margin-bottom: 30px;
			flex: 0 0 180px;
			text-align: center;
			font-weight: 600;
			&.active {
				border-bottom-color: #00baf2;
				color: #00baf2;
			}
		}
	}
	.plan-details-wrapper {
		max-height: 255px;
		overflow-y: auto;
		padding-right: 20px;
		&.plan-details-wrapper-mobile {
			max-height: 310px;
		}
		.details {
			display: flex;
			padding-bottom: 10px;
			border-bottom: 1px dashed #e5e5e5;
			margin-bottom: 30px;
			> div {
				flex: 0 0 25%;
				strong {
					font-size: 18px;
					font-weight: 500;
					display: inline-block;
					vertical-align: top;
				}
				span {
					color: #888;
					display: block;
				}
				&.select-link {
					text-align: right;
					strong {
						color: #00baf2;
						border: 2px solid #00baf2;
						padding: 5px 15px;
						text-align: center;
						min-width: 100px;
						border-radius: 2px;
						white-space: nowrap;
					}
				}
			}
		}
	}
`;

export const YellowStripWrapper = styled.div`
	&.yellow-strip {
		background-color:var(--login-btn-bg);
		color: var(--white);
		width: 100%;
		text-align: center;
		font-size: 15px;
		line-height: 23px;
		padding: 7px 10px;
		box-shadow: 0 1px 1px #dfdfdf;
	}
`;

export const TrippleDotWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	.tripple-dot {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: #777;
		position: relative;
		&::before,
		&::after {
			content: '';
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: #777;
			position: absolute;
		}
		&::before {
			top: 9px;
		}
		&::after {
			bottom: 9px;
		}
	}
`;

export const ModalWraper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	bottom: 0;
	background-color: #000000c4;
	/* background-color: rgba(0,0,0,.7); */
	display: flex;
	align-items: baseline;
	justify-content: center;
	overflow: auto;
	z-index: 1;
	.modal,
	.modal-success {
		border-radius: 2px;

		background: #fff url(${(props) => props.url}) 50% 0% no-repeat;
		background-size: 400px auto;

		width: ${(props) => (props.width ? props.width : '600px')};
		box-shadow: 0px 1px 99px #0000001a;
		position: relative;
		margin: 50px 0;
		padding: 35px 27px !important;
		.modal-header {
			display: flex;
			.logo {
				p {
					font-size: 10px;
					font-family: sans-serif;
					color: #000000;
					line-height: 15px;
				}
			}
			.buttons button {
				height: 32px;
				padding: 0 10px;
			}
		}
		.modal-title {
			font-size: 25px;
			color: #080f11;
			margin-bottom: 12px;
			h2 {
				margin-bottom: 4px;
			}
			.amount {
				font-size: 24px;
				color: #005090;
			}
			.amount-text {
				font-size: 13px;
			}
		}
		.modal-body {
			.txn-daital-h {
				font: normal 600 14px Poppins;
				color: #012d5a;
				margin-bottom: 12px;
				position: relative;
			}
			.txn-desc {
				font-size: 13px;
				font-weight: 500;
				color: #000000;
				.txn-desc-right {
				     
					& > div {
						margin-bottom: 6px;
						display: flex;
						gap: 8px;
						
					}
					.heading {
						display: inline-block;
						width: 110px;
					}
					span{
						display: inline-block;
						
					}
				}
			}
				.txn-desc-left {
					min-width: 220px;
					& > div {
						margin-bottom: 8px;
						display: flex;
						gap: 8px;
					}
					.date {
						text-align: end;
						font-size: 10px;
					}
					span{
					  display: block;
					}
				}
			
		}
		.modal-close {
			position: absolute;
			top: -16px;
			right: -16px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #fff;
			padding: 4px;
			z-index: 9;
			box-shadow: 0 0 5px #aaa;
			cursor: pointer;
		}
	}
	.modal-body-aeps {
		.success {
			color: green;
			font-size: 13px;
			img {
				margin-right: 4px;
				margin-top: 4px;
			}
		}
	}
	.MiniStatementList {
		.txn-daital-h {
			margin-bottom: 4px !important;
		}
		.MiniStatement {
			border: 1px solid #e8e8e8;
			border-radius: 4px;
			height: 150px;
			overflow-y: auto;
			table {
				width: 100%;

				thead tr {
					background-color: #f7f8f9;
					color: #012d5a;
					th {
						padding: 8px;
						text-align: start;
						&:last-child {
							text-align: end;
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 4px 8px;
							&.red {
								color: red;
							}
							&.green {
								color: green;
							}

							&:last-child {
								text-align: end;
							}
						}
					}
				}
			}
		}
	}
	.modal {
		.modal-footer {
			margin-top: 20px;
		}
	}
	@media print {
		background-color: #fff;
		overflow-x: hidden;
		visibility: visible;
		.modal-close {
			display: none;
		}

		.MiniStatement {
			height: auto;
		}
		.buttons button {
			display: none;
		}
	}
`;

export const LoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #00000056;
	display: flex;
	justify-content: center;
	align-items: center;

	.Loader {
		display: inline-block;
		position: relative;
		//-color: #fff;
		//box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
		//background-color: #00286e;
		border-radius: 50%;
		padding: 6px 10px;
		img {
			width: 40px;
			height: 54px;
			display: inline-block;
			border-radius: 8px;
		}
		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 70px;
			height: 70px;
			top: -8px;
			left: -11px;
			margin: 6px;
			border: 4px solid #00baf2;
			//border: 4px solid #00286e;

			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #00baf2 transparent transparent transparent;
			//border-color: #fff transparent transparent transparent;
			&:nth-child(1) {
				animation-delay: -0.45s;
			}
			&:nth-child(2) {
				animation-delay: -0.3s;
			}
			&:nth-child(3) {
				animation-delay: -0.15s;
			}
		}
		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
`;

export const AlertWrapper = styled.div`
	padding: 10px 25px 10px 10px;
	position: relative;
	margin-bottom: 10px;
	border: 1px solid transparent;
	border-radius: 5px;

	&.error {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}

	&.success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}
	&.globle {
		position: fixed;
		//width: 50%;
		max-width: 70%;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		z-index: 11111;
		font-size: 14px;
		animation: mymove 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

		.close {
			font-size: 18px;
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
		}
	}
	&.error.globle {
		background-color: #fee;
		border: 1px solid #ffb8b8;
		color: #a66;
	}
	&.success.globle {
		background-color: #efe;
		border: 1px solid #ded;
		color: #9a9;
	}
	.close {
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%);
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 0.6;
		&:hover {
			color: #000;
			text-decoration: none;
			cursor: pointer;
			filter: alpha(opacity=50);
			opacity: 0.5;
		}
	}
	@keyframes mymove {
		from {
			top: 0px;
		}
		to {
			top: 50px;
		}
	}
	@media only screen and (max-width: 767px) {
		&.globle {
			width: calc(100% - 50px);
		}
	}
`;

export const FilterWrapper = styled.div`
	height: 74px;
	width: 100%;
	background: transparent linear-gradient(90deg, #00baf2 0%, #002970 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 3px #0029700d;
	border: 1px solid #00297024;
	border-radius: 10px;
	opacity: 1;
	overflow: auto;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	padding: 10px 24px;
	font-family: 'Noto Serif', serif;
	.filterBox {
		display: flex;
		gap: 10px;
		align-items: center;
		width: 100%;

		.filter-text {
			font-size: 15px;
			color: #fff;
			font-weight: 600;
		}
		.input-field {
			input,
			select {
				padding: 9px 10px;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #fff;
				font-size: 12px;
				width: 142px;
				border: 2px solid transparent;
				&:focus {
					border: 2px solid #ffd700;
					outline: none;
				}
			}
			&.date-filter {
				padding: 0;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #fff;
				font-size: 12px;
				display: flex;
				align-items: center;
				position: relative;
				img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					&.img-1 {
						left: 8px;
					}
					&.img-2 {
						left: 125px;
					}
					&.img-3 {
						left: 240px;
					}
				}
				& > select {
					background-color: #edfafe;
					/* padding-left: 22px; */
					max-width: 120px;
					box-shadow: none;
					border-width: 0;
					&:focus-visible,
					&:focus {
						border-width: 0;
						outline: none;
					}
				}

				& > input {
					/* padding-left: 22px; */
					max-width: 130px;
					border-width: 0;
					box-shadow: none;
					&:focus-visible,
					&:focus {
						border-width: 0;
					}
				}
			}
			.filterButton {
				background: #00baf2 0% 0% no-repeat padding-box;
				box-shadow: 0px 2px 3px #0029700d;
				border: 1px solid #00297024;
				padding: 8px 12px;
				border-radius: 5px;
				color: #fff;
				cursor: pointer;
				.btn-loader {
					animation: spin 2s linear infinite;
				}
				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
			&.csv {
				margin-left: auto;
			}
		}
	}
`;
