import styled from 'styled-components';

export const Wrapper = styled.div`
	background-image: url('../../assets/images/bharatBanner.png');
	background-position: bottom right;
	background-repeat: no-repeat;
     background-color: var(--tab-content-bgColor);
	margin: -30px;
	padding-left: 47px;
	 height: 400px;
	 border-radius: 10px;


	.banner-text {
		padding: 80px 0 20px 0px;

		h1 {
			font-size: 47px;
			line-height: 42px;
			font-weight: bold;
			color: #141414;
			text-transform: uppercase;
		}
		.flat-text {
			font-size: 26px;
			line-height: 39px;
			color: #141414;
			font-weight: 500;
			margin-bottom: 13px;
		}
		i {
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
`;
