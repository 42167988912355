import React from "react";

export default function IconMobileRecharge({ color = '#fff',color_2="#a9a1a1" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.9"
      height="36.772"
      viewBox="0 0 33.9 36.772"
    >
      <g data-name="Group 65">
        <g fill="none" data-name="Group 63">
          <g data-name="Path 355">
            <path d="M4.6 0h12.64a4.6 4.6 0 014.6 4.6v27.576a4.6 4.6 0 01-4.6 4.6H4.6a4.6 4.6 0 01-4.6-4.6V4.6A4.6 4.6 0 014.6 0z"></path>
            <path
            className="path-color"
              fill={color}
              d="M4.597 1.5A3.1 3.1 0 001.5 4.597v27.579a3.1 3.1 0 003.097 3.096h12.64a3.1 3.1 0 003.097-3.096V4.596A3.1 3.1 0 0017.237 1.5H4.597m0-1.5h12.64a4.597 4.597 0 014.597 4.597v27.579a4.597 4.597 0 01-4.597 4.596H4.597A4.597 4.597 0 010 32.176V4.596A4.597 4.597 0 014.597 0z"
            ></path>
          </g>
          <path
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M8.618 4.508h5.432"
            data-name="Path 186"
          ></path>
          <path
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1"
            d="M6.32 32.087h9.053"
            data-name="Path 187"
          ></path>
        </g>
        <g data-name="Group 64" transform="translate(9.767 10.318)">
          <path
            fill={color_2}
            d="M0 12.309V0h24.133v12.309H2.86L0 16.136z"
            data-name="Union 2"
          ></path>
          <text
            fill="#fff"
            data-name="965****"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="4"
            fontWeight="500"
            transform="translate(8.149 6.873)"
          >
            <tspan x="-6" y="0">
              965****
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}