import React, { useState } from 'react';
import './faq.css';
function Faq() {
	const [answer1, setAnswers1] = useState('');
	const [isClicked, setIsClicked] = useState(false);

	const displayAnswers = (e, key) => {
		if (key === answer1) {
			setAnswers1('');
			setIsClicked(!isClicked);
		} else {
			setAnswers1(key);
			setIsClicked(key);
		}
	};

	return (
		<div className="faq-wrapper">
			<div className="heading">
				<h2 className="m-auto">Frequently Asked Questions</h2>
			</div>
			<div className="faqwrapper">
				<div className="faq-heading mb20 text-center ">
					<h2 className="m-auto">Frequently Asked Questions</h2>
				</div>
				<div className="faq-container mb20">
					<div className="faqs">
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-1')}>
									{isClicked === 'tab-1' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>How to use this web portal?</h6>
							</div>
						</div>
						{answer1 === 'tab-1' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>
										Login using your registered mobile number and password. Select the type of payments you want to do.Fill all the data and then continue your payment/transaction.
									</h6>
								</div>
							</div>
						)}
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-2')}>
									{isClicked === 'tab-2' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>How we can change Password?</h6>
							</div>
						</div>
						{answer1 === 'tab-2' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>Go to Login Page. Click on forgot password.</h6>
								</div>
							</div>
						)}

						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-3')}>
									{isClicked === 'tab-3' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>Can I do the payments using my bank account?</h6>
							</div>
						</div>
						{answer1 === 'tab-3' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>No,You can't do payments using your bank account. You need to add money in your wallet to do any payment.</h6>
								</div>
							</div>
						)}
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-4')}>
									{isClicked === 'tab-4' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>What is AEPS?</h6>
							</div>
						</div>
						{answer1 === 'tab-4' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>
										AEPS is a payment system developed by the National Payments Corporation of India(NPCI) that allows online interoperable financial inclusion transaction at
										Micro-ATM through the Business Correspondent of any bank using the Aadhar authentication.
									</h6>
								</div>
							</div>
						)}
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-5')}>
									{isClicked === 'tab-5' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>How does AEPS work?</h6>
							</div>
						</div>
						{answer1 === 'tab-5' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>
										{' '}
										AEPS works by allowing customers to make financial transactions using their Aadhar number and biometric authentication. It uses the Aadhar database for
										verification and enables to add money to the Wallet.
									</h6>
								</div>
							</div>
						)}
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-6')}>
									{isClicked === 'tab-6' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>How can I make a DTH payment?</h6>
							</div>
						</div>
						{answer1 === 'tab-6' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6> Click on DTH tab.Fill the data like search operator service name .Click on Continue.</h6>
								</div>
							</div>
						)}
						<div className="faqQuestions flex">
							<div>
								<button className="question-btn" onClick={(e) => displayAnswers(e, 'tab-7')}>
									{isClicked === 'tab-7' ? <i class="fa-solid fa-circle-minus faq-img fa-xl"></i> : <i class="fa-solid fa-circle-plus faq-img fa-xl"></i>}
								</button>
							</div>
							<div className="questions">
								<h6>Can I do Payments using mobile app?</h6>
							</div>
						</div>
						{answer1 === 'tab-7' && (
							<div className="faqQuestions flex">
								<div className="answers">
									<h6>Yes, You can do all the type of tranctions/payments using our mobile app.</h6>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Faq;
