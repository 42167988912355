import React from "react";

export default function IconAddmoney({ color = "#fff",color_2="#a9a1a1" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.495"
      height="37.123"
      viewBox="0 0 39.495 37.123"
    >
      <g data-name="Group 297" transform="translate(-1178.505 -129)">
        <g data-name="Group 295" transform="translate(959.605 -166.373)">
          <g data-name="Group 283" transform="translate(219.65 299)">
            <rect
              width="32.745"
              height="32.745"
              fill="none"
              className="path-stroke"
              stroke={color}
              strokeWidth="1.5"
              data-name="Rectangle 560"
              rx="16.373"
            ></rect>
            <path
              fill={color_2}
              d="M15.32 4.446a1.025 1.025 0 01-1.027 1.027h-1.04a5.144 5.144 0 01-5.12 4.791H6.9l5.3 4.339a1.023 1.023 0 01.144 1.444 1.035 1.035 0 01-.794.376 1.025 1.025 0 01-.65-.233l-7.529-6.16a1.032 1.032 0 01.655-1.83h4.107a3.083 3.083 0 003.06-2.738H4.026a1.027 1.027 0 110-2.053h6.667a3.07 3.07 0 00-2.56-1.369H4.026a1.027 1.027 0 010-2.053h10.267a1.027 1.027 0 110 2.053H12.24a5.286 5.286 0 01.739 1.369H14.3a1.025 1.025 0 011.027 1.027z"
              transform="translate(7.234 8.759)"
            ></path>
          </g>
        </g>
        <g data-name="Group 296" transform="translate(875 -39)">
          <path
            className="path-color"
            fill={color}
            d="M8 0a8 8 0 11-8 8 8 8 0 018-8z"
            data-name="Path 556"
            transform="translate(327 168)"
          ></path>
          <g
            fill="none"
            className="path-stroke-white"
            stroke="#002970"
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Group 237"
            transform="translate(7.806 -7.5)"
          >
            <path d="M327.194 179.5v7.612" data-name="Path 353"></path>
            <path d="M330.806 183.5h-7.612" data-name="Path 354"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}