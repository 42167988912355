import React from "react";

export default function IconDTH({ color = '#fff',color_2="#a9a1a1"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.72"
      height="38.402"
      viewBox="0 0 32.72 38.402"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H32.72V38.402H0z"
            data-name="Rectangle 111"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 87">
        <g clipPath="url(#clip-path)" data-name="Group 86">
          <path
            fill="none"
            d="M3.274 24.248A14.707 14.707 0 1024.188 3.566z"
            data-name="Path 229"
            transform="translate(.479 .522)"
          ></path>
          <path
            fill="none"
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M3.753 24.77a14.707 14.707 0 0020.8.116 14.888 14.888 0 003.846-6.575 14.594 14.594 0 00-3.732-14.223z"
            data-name="Path 230"
          ></path>
          <path
            fill="none"
            d="M22.495 20.317h-7.562l-1.372 14.252h10.306z"
            data-name="Path 231"
            transform="translate(1.984 2.973)"
          ></path>
          <path
            fill="none"
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M25.852 37.541H15.545l.825-8.564a14.6 14.6 0 008.181-4.092l.075-.074 1.225 12.729z"
            data-name="Subtraction 3"
          ></path>
          <path
            fill="none"
            d="M22.576 20.829a4.009 4.009 0 11-4.009-4.009 4.009 4.009 0 014.009 4.009"
            data-name="Path 233"
            transform="translate(2.13 2.461)"
          ></path>
          <ellipse
            cx="4.009"
            cy="4.009"
            fill="none"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Ellipse 46"
            rx="4.009"
            ry="4.009"
            transform="translate(16.078 19.282)"
          ></ellipse>
          <path
            fill="#fff3f4"
            d="M3.868 3.868L0 0"
            data-name="Line 22"
            transform="translate(10.43 10.474)"
          ></path>
          <path
            fill="none"
            stroke="#00286f"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M3.868 3.868L0 0"
            data-name="Line 23"
            transform="translate(10.43 10.474)"
          ></path>
          <path
            fill="none"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M16.079 3.51a8.889 8.889 0 00-12.66 12.479"
            data-name="Path 234"
          ></path>
          <path
            fill="none"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M13.61 5.954a5.414 5.414 0 10-7.711 7.6"
            data-name="Path 235"
          ></path>
          <path
            fill="none"
            d="M9.869 8.706a1.357 1.357 0 11-1.358-1.357 1.357 1.357 0 011.358 1.357"
            data-name="Path 236"
            transform="translate(1.047 1.075)"
          ></path>
          <ellipse
            cx="1.357"
            cy="1.357"
            fill="none"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Ellipse 47"
            rx="1.357"
            ry="1.357"
            transform="translate(8.201 8.424)"
          ></ellipse>
          <path
            fill="#fff3f4"
            d="M0 0L25.813 0"
            data-name="Line 24"
            transform="translate(6.046 37.542)"
          ></path>
          <path
            fill="none"
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M0 0L25.813 0"
            data-name="Line 25"
            transform="translate(6.046 37.542)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
