import React from "react";

export default function IconAEPS({ color = "#fff",color_2="#a9a1a1"} ) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.995"
      height="38.856"
      viewBox="0 0 28.995 38.856"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill={color}
            d="M0 0H28.995V38.856H0z"
            data-name="Rectangle 532"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 409">
        <g clipPath="url(#clip-path)" data-name="Group 240">
          <path
            className="path-color"
            fill={color}
            d="M20.156 16.109c8.611 0 13.867 4.62 15.32 12.2a18.8 18.8 0 01.332 5.587c-.07.643-.108 1.366-.983 1.293s-.814-.824-.8-1.448a20.784 20.784 0 00-.953-7.162c-1.409-4.3-4.171-7.177-8.68-8.165-.621-.136-1.621-.55-1.824.287-.181.751.836.836 1.459 1 5.244 1.363 8.146 4.815 8.722 10.185a15.848 15.848 0 01-.457 6.025 13.257 13.257 0 00-.608 2.819 7.639 7.639 0 01-2.538 4.889.974.974 0 01-1.459.136.913.913 0 01-.042-1.366 14.078 14.078 0 002.611-5.056 23.442 23.442 0 00.713-4.885 14.961 14.961 0 00-.327-3.84c-1.035-4.817-4.809-6.931-8.928-7.244-4.4-.335-7.792 1.5-10.346 5.018-.161.221-.314.448-.488.659a.842.842 0 01-1.139.287.818.818 0 01-.258-1.228 11.39 11.39 0 012.681-3.377 13.141 13.141 0 018.543-3.35c.5.005 1.143.045 1.062-.718-.073-.688-.644-.48-1.108-.453a21.333 21.333 0 00-9.57 3.212 9.135 9.135 0 01-.823.444.8.8 0 01-1.047-.248.867.867 0 01.043-1.178 2.232 2.232 0 01.526-.459c3.634-2.277 7.517-3.8 10.366-3.859"
            data-name="Path 357"
            transform="translate(-6.897 -12.331)"
          ></path>
          <path
            fill={color_2}
            d="M37.491 70.978c-.9 0-1.8-.011-2.694 0-1.248.019-1.542.365-1.635 1.872-.059.958.488 1.574 1.587 1.6 1.951.051 3.9.02 5.856.033 1.335.009 1.419.148 1.021 1.437a2.572 2.572 0 01-2.326 2 20.118 20.118 0 01-6.06-.233 3.994 3.994 0 01-3.469-4.447 23.789 23.789 0 012.035-8.6c1.8-3.658 5.661-4.439 9.144-3.476 2.171.6 3.258 2.868 2.893 5.507a12.954 12.954 0 01-.423 1.829 2.981 2.981 0 01-3.12 2.459c-.935.043-1.874.008-2.81.008v.011m-.231-3.509v.011c.582 0 1.164.006 1.746 0s1.167.071 1.351-.747a1.886 1.886 0 00-.6-2.195c-1.663-1.167-4.923.212-5.272 2.183-.091.516.06.744.564.748.737.006 1.474 0 2.211 0"
            data-name="Path 358"
            transform="translate(-22.776 -46.534)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M13.038 108.272a10.427 10.427 0 006.438-1.932c.16-.111.327-.213.494-.313.486-.289.984-.367 1.356.139.437.593.068 1.029-.4 1.379a12.938 12.938 0 01-6.325 2.571 13.035 13.035 0 01-11.129-3.447C-.083 103.2-.624 98.861.576 94.2c.144-.553.424-1.073 1.124-.924s.731.755.646 1.313a15.963 15.963 0 00-.374 4.993 10.157 10.157 0 0010.061 8.69h1"
            data-name="Path 359"
            transform="translate(0 -71.383)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M22.675 52.518a1.3 1.3 0 01-.555-1.707 10.035 10.035 0 0113.813-5.489c2.354 1.128 3.6 3.258 4.308 5.706a2.154 2.154 0 01.055.58c0 .586-.122 1.142-.8 1.237s-.992-.42-1.072-.99a7.14 7.14 0 00-1.668-3.425 4.665 4.665 0 00-2.913-1.814c-4.165-.723-7.629.154-9.735 4.495-.309.637-.62 1.281-1.434 1.407z"
            data-name="Path 360"
            transform="translate(-16.835 -33.927)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M25.988 0a22.29 22.29 0 0113.144 4.078c.587.4 1.26.876.676 1.693-.532.743-1.186.337-1.747-.044A21.673 21.673 0 0033.1 3.133a18.4 18.4 0 00-13.158.153c-.146.056-.294.107-.439.162-.577.22-1.116.19-1.4-.424-.322-.7.125-1.125.7-1.373a20.47 20.47 0 012.414-.886A17.751 17.751 0 0125.988 0"
            data-name="Path 361"
            transform="translate(-13.772)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M16.62 79.276A1.453 1.453 0 0117.229 81a32.123 32.123 0 00-1.076 6.088c-.051 3.93 2.093 6.487 5.968 7.128.192.032.388.039.58.074.63.114 1.17.389 1.087 1.122s-.652.924-1.3.861c-4.148-.4-7.444-2.8-8.24-7.168-.567-3.111.484-6.012 1.132-8.963a1.134 1.134 0 011.242-.861"
            data-name="Path 362"
            transform="translate(-10.782 -60.686)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M2.7 68.426a7.434 7.434 0 01.809-2.831c.307-.559.764-.946 1.425-.62s.649.9.351 1.492a5.544 5.544 0 00-.645 2.214c-.04.656-.247 1.319-1.047 1.279-.915-.041-.9-.795-.9-1.534"
            data-name="Path 363"
            transform="translate(-2.064 -49.647)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M62.629 141.738c-.811-.016-1.567-.054-1.616-.934s.669-1.023 1.381-1.039a6.576 6.576 0 002.143-.54c.585-.217 1.2-.295 1.512.394s-.161 1.1-.735 1.361a7.876 7.876 0 01-2.684.757"
            data-name="Path 364"
            transform="translate(-46.704 -106.46)"
          ></path>
        </g>
      </g>
    </svg>
  );
}