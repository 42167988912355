import React from 'react';

function IconPipeGash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38.19" height="40.093" viewBox="0 0 38.19 40.093">
  <g id="Group_289" data-name="Group 289" transform="translate(0 1.829)">
    <g id="Group_288" data-name="Group 288" transform="translate(0 13.68)">
      <g id="Rectangle_566" data-name="Rectangle 566" transform="translate(0 12.086)" fill="none" stroke="#c8cced" stroke-linecap="round" stroke-width="1.5">
        <rect width="38.19" height="11.804" rx="3" stroke="none"/>
        <rect x="0.75" y="0.75" width="36.69" height="10.304" rx="2.25" fill="none"/>
      </g>
      <g id="Rectangle_567" data-name="Rectangle 567" transform="translate(18.053 3.059)" fill="#fff" stroke="#c8cced" stroke-linecap="round" stroke-width="1.5">
        <rect width="2.083" height="10.415" stroke="none"/>
        <rect x="0.75" y="0.75" width="0.583" height="8.915" fill="none"/>
      </g>
      <g id="Group_287" data-name="Group 287" transform="translate(6.249 9.309)">
        <g id="Rectangle_570" data-name="Rectangle 570" transform="translate(0 0)" fill="none" stroke="#c8cced" stroke-linecap="round" stroke-width="1.5">
          <rect width="6.944" height="15.276" rx="3.472" stroke="none"/>
          <rect x="0.75" y="0.75" width="5.444" height="13.776" rx="2.722" fill="none"/>
        </g>
        <g id="Rectangle_571" data-name="Rectangle 571" transform="translate(18.747 0)" fill="none" stroke="#c8cced" stroke-linecap="round" stroke-width="1.5">
          <rect width="6.249" height="15.276" rx="3.125" stroke="none"/>
          <rect x="0.75" y="0.75" width="4.749" height="13.776" rx="2.375" fill="none"/>
        </g>
      </g>
      <g id="Rectangle_568" data-name="Rectangle 568" transform="translate(26.697 0) rotate(90)" fill="#fff" stroke="#c8cced" stroke-linecap="round" stroke-width="1.5">
        <rect width="5.653" height="15.432" rx="2.827" stroke="none"/>
        <rect x="0.75" y="0.75" width="4.153" height="13.932" rx="2.077" fill="none"/>
      </g>
    </g>
    <path id="Path_552" data-name="Path 552" d="M12.658-1.513a11.7,11.7,0,0,0-.865,7.02c.632,4.392,2.329,5.091,2.711,5.11.416.28,3.528-6.175,3.528-3.488,5.723,8.368-.687,14.4-6.711,14.4S2.144,16.642,2.144,10.618c.1-3.047,2.1,1.577,3.793,2.509A8.84,8.84,0,0,1,5.6,5.308C7.594,1.1,12.658-1.513,12.658-1.513Z" transform="translate(7.827 1.513)" fill="#3b1338" stroke="#fa5a6b" stroke-linecap="round" stroke-width="1.5"/>
  </g>
</svg>

  );
}

export default IconPipeGash;
