import { Wrapper } from './style';

import img from '../../assets/images/barat-1.png'

function Component() {
	return (
		<Wrapper>
			<div className="container flex justify-between">
				<div className="img self-end">
					<img className="w-100" src={img} alt="img" />
				</div>
				<div className="about-text self-center mb20">
					<h2 className="heading-2">
						About the <span className="strong">Spectra Traders </span>
					</h2>
					<p>
						Recharging your mobile or paying your utility bill is easier than ever. A savings bank account or a wallet with Airtel Payments Bank is all that you need to pay your bills with
						just a tap.
					</p>
				</div>
			</div>
		</Wrapper>
	);
}

export default Component;
