import React from 'react'
import './Services.css'
import '../Company/Company.css'
import Recharge from "../../assets/images/recharge.png"
import bankPay from "../../assets/images/bankpay.png"
import dthRecharge from "../../assets/images/dthRecharge.png"
import electricityBill from "../../assets/images/electricityBill.png"
import gasBill from "../../assets/images/gasbill.png"
import emiBill from "../../assets/images/emiBill.png"
import insurancePremium from "../../assets/images/insurancePreminum.png"
import img1 from '../../assets/images/c1.svg';
import img2 from '../../assets/images/c2.svg';
import img3 from '../../assets/images/c3.svg';
import img4 from '../../assets/images/c4.svg';


function Services() {
   

    return (

        <div className='services-wrapper'>
            {/* <a ref={link} href={sectionId}></a> */}
            <div className="heading">
                <h2 className='m-auto'>Services</h2>
            </div>
            <div className='serviceswrapper'>
                <div className="container">
                    <div className="consumer-top flex justify-center gap-50 mb20">
                        <div className="img mt20">
                            <img src={Recharge} alt="img" />
                        </div>
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '>
                                <span className='strong'>Prepaid and Postpaid Recharge</span></h2>
                            <p>Spectra Traders  offers you attractive offers and coupons for postpaid mobile recharge with the colossal amount of cashback, like icing on the cake. Not only online postpaid mobile recharge, but it also provides us with other postpaid bill payment facilities such as d2h, landline, rent, electricity, gas, postpaid mobile recharge, and broadband bill payment.</p>
                        </div>
                    </div>
                    <div className="consumer-top  consumer-middle flex justify-center gap-50 mb20 bg-color">
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '><span className='strong'>Online DTH Recharge for All DTH Operators with Spectra Traders </span></h2>
                            <p>Spectra Traders  is one of the best platforms to recharge DTH online. It allows you to recharge your DTH within a few clicks for any service provider. You might be out of town and don't have time to go to a physical store. Even if you don't have a DTH provider nearby, you can recharge your TV for any operator online.</p>
                        </div>
                        <div className="img">
                            <img src={dthRecharge} alt="img" />
                        </div>
                    </div>
                    <div className="consumer-top flex justify-center gap-50 mb20">
                        <div className="img">
                            <img src={electricityBill} alt="img" />
                        </div>
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '>
                                <span className='strong'>Electricity Bill Payment Online with Spectra Traders </span></h2>
                            <p>Pay electricity bill online is easier than ever with Spectra Traders  Payments Bank. A savings bank account or a wallet with Spectra Traders  Payments Bank is what you need to pay your bills online with just a tap. Furthermore, you can make your EB bill payment from the comfort of your home at any time and from anywhere.</p>
                        </div>
                   </div>
                    <div className="consumer-top  consumer-middle flex justify-center gap-50 mb20">
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '><span className='strong'>Loan EMI Payment with Spectra Traders </span></h2>
                            <p>The loan sector in the country, and even across the world, has grown multi-fold in the last two decades. From setting up a business or purchasing property to buying a car or an electronic appliance, the variety of loan options available in the market is huge.</p>
                        </div>
                        <div className="img">
                            <img src={emiBill} alt="img" />
                        </div>
                    </div>
                    <div className="consumer-top flex justify-center gap-50 mb20 bg-color">
                        <div className="img mt20">
                            <img src={gasBill} alt="img" />
                        </div>
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '>
                                <span className='strong'>Gas Bill Payment with Spectra Traders </span></h2>
                            <p>Gas bill payment online has become an effective and helpful method with Spectra Traders . No matter who your gas service provider is, search well in the portal and make your online payment. You can simply trust Freecharge as it’s the most flexible and safe platform for your online bill payments.</p>
                        </div>
                    </div>
                    <div className="consumer-top  consumer-middle flex justify-center gap-50 mb20">
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '><span className='strong'>Insurance Premium Payment Online From Spectra Traders </span></h2>
                            <p>As technology is evolving at a faster pace than ever, the online services trend has completely swept away the traditional in-person mode of services. One such example is the online premium payment. Today, almost every financial institution allows you to pay your insurance premium online any time, from anywhere.</p>
                        </div>
                        <div className="img">
                            <img src={insurancePremium} alt="img" />
                        </div>
                    </div>
                    <div className="consumer-top flex justify-center gap-50 mb20">
                        <div className="img">
                            <img src={bankPay} alt="img" />
                        </div>
                        <div className="desc self-center">
                            <h2 className='heading-2 mb20 '>
                                <span className='strong'>Pay anyone directly from your bank account.</span></h2>
                            <p>Pay anyone, everywhere. Make contactless & secure payments in-stores or online using Spectra Traders  Wallet or Directly from your Bank Account. Plus, send & receive money from anyone.</p>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className="top text-center">
                        <h2 className='heading-2 mb20 text-center'><span className='strong'>What makes us different</span></h2>
                        <p>Leading mobile finance solution and every FinTech’s first choice. There are many things that makes Pay2Mpbiles different from the rest. Some of them are mentioned here.</p>
                    </div>
                    <div className="card-box flex justify-center gap-30">
                        <div className="card text-center">
                            <div className="img center"> <img className='mb30' src={img1} alt="img-1" /></div>
                            <h3>Customer satisfaction</h3>
                            <p>Customer satisfaction is always our top priority and we ensure that by fulfilling all our client’s requirements.</p>
                        </div>
                        <div className="card text-center">
                            <div className="img center"> <img className='mb30' src={img2} alt="img-1" /></div>
                            <h3>Committed support</h3>
                            <p>Our proficient professionals are always committed to provide 24*7 support via calls, email, chat, and other media.</p>
                        </div>
                        <div className="card text-center">

                            <div className="img center"> <img className='mb30' src={img3} alt="img-1" /></div>
                            <h3>Peace of mind</h3>
                            <p>We work with full dedication to ensure that the project gets completed on time. Thus, providing you the peace of mind.</p>
                        </div>
                        <div className="card text-center">

                            <div className="img center"> <img className='mb30' src={img4} alt="img-1" /></div>
                            <h3>100% transparency</h3>
                            <p>Keeping 100% transparency is our mantra as we keep all the unexpected surprises away from you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
