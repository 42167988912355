import React from 'react';

function IconLandLine({ color = '#fff',color_2="#a9a1a1"}) {
  return (
    

<svg id="Group_290" data-name="Group 290" xmlns="http://www.w3.org/2000/svg" width="31.94" height="31.246" viewBox="0 0 31.94 31.246">
  <g id="Rectangle_572" data-name="Rectangle 572" transform="translate(0 2.083)" fill="none" stroke={color} stroke-width="1.5">
    <rect width="31.94" height="29.163" rx="4" stroke="none"/>
    <rect x="0.75" y="0.75" width="30.44" height="27.663" rx="3.25" fill="none"/>
  </g>
  <g id="Rectangle_574" data-name="Rectangle 574" transform="translate(18.053 2.083)" fill="none" stroke={color} stroke-width="1.5">
    <path d="M0,0H9.887a4,4,0,0,1,4,4V7.638a0,0,0,0,1,0,0H4a4,4,0,0,1-4-4V0A0,0,0,0,1,0,0Z" stroke="none"/>
    <path d="M1.5.75H9.887A3.25,3.25,0,0,1,13.137,4V6.138a.75.75,0,0,1-.75.75H4A3.25,3.25,0,0,1,.75,3.638V1.5A.75.75,0,0,1,1.5.75Z" fill="none"/>
  </g>
  <g id="Path_5819" data-name="Path 5819" transform="translate(0 0)" fill="#3b1338">
    <path d="M 5.415336132049561 24.9411563873291 L 4.999995708465576 24.9411563873291 C 2.656535863876343 24.9411563873291 0.7499959468841553 23.03461647033691 0.7499959468841553 20.6911563873291 L 0.7499959468841553 4.999996185302734 C 0.7499959468841553 2.656536340713501 2.656535863876343 0.7499963045120239 4.999995708465576 0.7499963045120239 L 5.415336132049561 0.7499963045120239 C 7.758795738220215 0.7499963045120239 9.665335655212402 2.656536340713501 9.665335655212402 4.999996185302734 L 9.665335655212402 20.6911563873291 C 9.665335655212402 23.03461647033691 7.758795738220215 24.9411563873291 5.415336132049561 24.9411563873291 Z" stroke="none"/>
    <path d="M 4.999995708465576 1.499996185302734 C 3.070086002349854 1.499996185302734 1.499996185302734 3.070085525512695 1.499996185302734 4.999996185302734 L 1.499996185302734 20.6911563873291 C 1.499996185302734 22.62106704711914 3.070086002349854 24.1911563873291 4.999995708465576 24.1911563873291 L 5.415336132049561 24.1911563873291 C 7.345245838165283 24.1911563873291 8.915335655212402 22.62106704711914 8.915335655212402 20.6911563873291 L 8.915335655212402 4.999996185302734 C 8.915335655212402 3.070085525512695 7.345245838165283 1.499996185302734 5.415336132049561 1.499996185302734 L 4.999995708465576 1.499996185302734 M 4.999995708465576 -3.814697265625e-06 L 5.415336132049561 -3.814697265625e-06 C 8.176755905151367 -3.814697265625e-06 10.4153356552124 2.238576889038086 10.4153356552124 4.999996185302734 L 10.4153356552124 20.6911563873291 C 10.4153356552124 23.45257568359375 8.176755905151367 25.6911563873291 5.415336132049561 25.6911563873291 L 4.999995708465576 25.6911563873291 C 2.23857593536377 25.6911563873291 -3.814697265625e-06 23.45257568359375 -3.814697265625e-06 20.6911563873291 L -3.814697265625e-06 4.999996185302734 C -3.814697265625e-06 2.238576889038086 2.23857593536377 -3.814697265625e-06 4.999995708465576 -3.814697265625e-06 Z" stroke="none" fill="#c8cced"/>
  </g>
  <g id="Ellipse_61" data-name="Ellipse 61" transform="translate(12.498 13.193)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_66" data-name="Ellipse 66" transform="translate(12.498 18.053)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_69" data-name="Ellipse 69" transform="translate(12.498 22.219)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_62" data-name="Ellipse 62" transform="translate(18.053 13.193)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_65" data-name="Ellipse 65" transform="translate(18.053 18.053)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_68" data-name="Ellipse 68" transform="translate(18.053 22.219)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Path_5811" data-name="Path 5811" transform="translate(23.608 13.193)" fill={color_2}>
    <path d="M 1.735888719558716 2.721778631210327 C 1.1922687292099 2.721778631210327 0.7499987483024597 2.279508829116821 0.7499987483024597 1.735888719558716 C 0.7499987483024597 1.1922687292099 1.1922687292099 0.7499987483024597 1.735888719558716 0.7499987483024597 C 2.279508829116821 0.7499987483024597 2.721778631210327 1.1922687292099 2.721778631210327 1.735888719558716 C 2.721778631210327 2.279508829116821 2.279508829116821 2.721778631210327 1.735888719558716 2.721778631210327 Z" stroke="none"/>
    <path d="M 1.735888719558716 1.499998688697815 C 1.605818748474121 1.499998688697815 1.499998688697815 1.605818748474121 1.499998688697815 1.735888719558716 C 1.499998688697815 1.865958690643311 1.605818748474121 1.971778750419617 1.735888719558716 1.971778750419617 C 1.865958690643311 1.971778750419617 1.971778750419617 1.865958690643311 1.971778750419617 1.735888719558716 C 1.971778750419617 1.605818748474121 1.865958690643311 1.499998688697815 1.735888719558716 1.499998688697815 M 1.735888719558716 -1.192092895507812e-06 C 2.694588661193848 -1.192092895507812e-06 3.471778631210327 0.777188777923584 3.471778631210327 1.735888719558716 C 3.471778631210327 2.694588661193848 2.694588661193848 3.471778631210327 1.735888719558716 3.471778631210327 C 0.777188777923584 3.471778631210327 -1.192092895507812e-06 2.694588661193848 -1.192092895507812e-06 1.735888719558716 C -1.192092895507812e-06 0.777188777923584 0.777188777923584 -1.192092895507812e-06 1.735888719558716 -1.192092895507812e-06 Z" stroke="none" fill={color_2}/>
  </g>
  <g id="Ellipse_64" data-name="Ellipse 64" transform="translate(23.608 18.053)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
  <g id="Ellipse_67" data-name="Ellipse 67" transform="translate(23.608 22.219)" fill="#fff" stroke={color} stroke-width="1.5">
    <circle cx="1.736" cy="1.736" r="1.736" stroke="none"/>
    <circle cx="1.736" cy="1.736" r="0.986" fill="none"/>
  </g>
</svg>


  );
}

export default IconLandLine;
