import React from "react";
import IconAddmoney from "../../assets/images/IconAddmoney";
import IconAEPS from '../../assets/images/IconAEPS';
import IconBillRecharge from "../../assets/images/IconBillRecharge";
import IconBroadband from "../../assets/images/IconBroadband";
import IconDTH from "../../assets/images/IconDTH";
import IconExploreMore from "../../assets/images/IconExploreMore";
import IconMobileRecharge from "../../assets/images/IconMobileRecharge";
import IconSendmoney from "../../assets/images/IconSendmoney";
import IconCredit from '../../assets/images/IconCredit';
import IconWater from "../../assets/images/IconWater";
import IconPipeGash from "../../assets/images/IconPipeGash";
import IconLandLine from "../../assets/images/IconLandLine";

const iconMap = {
  IconAEPS: <IconAEPS color={"#e3871e"} />,
  IconMobileRecharge: <IconMobileRecharge color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconBillRecharge: <IconBillRecharge color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconBroadband: <IconBroadband color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconDTH: <IconDTH color={"#C8CCED"} />,
  IconSendmoney: <IconSendmoney color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconAddmoney: <IconAddmoney color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconCreditCard: <IconCredit color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconWater: <IconWater color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconExploreMore: <IconExploreMore color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconPipeGash: <IconPipeGash color={"#C8CCED"} color_2={"#FA5A6B"} />,
  IconLandLine: <IconLandLine color={"#C8CCED"} color_2={"#FA5A6B"} />,
}

const TabNavItem = ({ id, title, isActive, setActiveTab, icon = "" }) => {
 
 
return (
   <div onClick={() =>setActiveTab(id)} className={isActive ? "nav-link active" : "nav-link"}>
    <div className="pic">
		{iconMap?.[icon]}
	</div>
		<span>{title}</span>
   </div>
 );
};
export default TabNavItem;